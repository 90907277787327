import React from "react"

interface VideoProps {
  videoSrcURL: string;
  videoTitle: string;
}

export const Video: React.FC<VideoProps> = ({videoSrcURL, videoTitle, ...rest}) => (
  <div className="video">
    <iframe
      title={videoTitle}
      width="1200"
      height="700"
      src={videoSrcURL}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </div>
)
