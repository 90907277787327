import React from "react";
import {Introduction} from "../components/faq/introduction";
import {Details} from "../components/faq/details";
import {NormalLayout} from "../components/layouts/normal_layout";
import {SEO} from "../components/seo";


const Faq: React.FC = () => {

  return (
      <NormalLayout>
        <SEO title="FAQ | UV Tech by Rendev" description="FAQ | UV Tech by Rendev" />
        <Introduction />
        <Details />
      </NormalLayout>
  )
}

export default Faq;