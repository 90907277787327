import React from "react";
import {useTranslation} from "react-i18next";
import {Video} from "../common/video";
import {Section} from "../common/section";

export const Details: React.FC = () => {
  const {t} = useTranslation();

  return (
      <Section>
        <h2>{t('faq.details.heading-00')}</h2>
        <p>{t('faq.details.description')}</p>

        <Video
          videoSrcURL="https://www.youtube.com/embed/edsOS8cRFYs"
          videoTitle="What is UVC"
          />

        <h2>{t('faq.listitems.heading-00')}</h2>

          <ul>
            <li>{t('faq.listitems.item-00')}</li>
            <li>{t('faq.listitems.item-01')}</li>
            <li>{t('faq.listitems.item-02')}</li>
            <li>{t('faq.listitems.item-03')}</li>
            <li>{t('faq.listitems.item-04')}</li>
            <li>{t('faq.listitems.item-05')}</li>
          </ul>

          <p>{t('faq.details.paragraph-00')}</p>
          <p>{t('faq.details.paragraph-01')}</p>
          <p>{t('faq.details.paragraph-02')}</p>
          <p>{t('faq.details.paragraph-03')}</p>

          

      </Section>
  )
}
